import Normal from '../assets2/auction_icons/igi_auction_normal.png';
import MaxBidIcon from '../assets2/auction_icons/max_bid_icon.png';
import Ducthauction from '../assets2/auction_icons/igi_flash_auction_dutch.png';
import Flashauction from '../assets2/auction_icons/igi_flash_auction_normal.png';
import Sealedbid from '../assets2/auction_icons/igi_auction_sealed_bid.png';
import Volumereserve from '../assets2/auction_icons/igi_auction_volume_reserve.png';
import Giveawey from '../assets2/auction_icons/igi_giveaway.png';
import Verifiedblue from '../assets2/auction_icons/VerifiedBlue.png';
import Discount from '../assets2/auction_icons/igi_discount.png';
import Purchase from '../assets2/auction_icons/igi_purchase.png';
import Yankee from '../assets2/auction_icons/yankee.png';

import React, { useState } from 'react';
import { bool } from 'prop-types';

const AuctionIcon = (value, type, max_bid = false,  charge_highest_bid = false) => {
  // console.log(value, type)

  //   if (type == undefined) {
  //     var atype = 'Normal';
  //   }

  let obj = {
    icon: '',
    message: '',
    longmessage: '',
    title: '',
  };

  if (value == 'Buy Only' && type == 'Normal') {
    obj.icon = <img src={Purchase} alt="icon" style={{ width: '40px' }}></img>;
    obj.title = 'Purchase';
    obj.message =
      'This item(s) is offered for sale at the price listed.  Users initiating purchase orders will be fulfilled in the order in which they were received, while supplies last.';

    obj.longmessage =
      'This item(s) is offered for sale at the price listed.  Users initiating purchase orders will be fulfilled in the order in which they were received, while supplies last.';

    return obj;
  }

  if (value == 'certified_item' && type == 'no_certificate_image') {
    obj.icon = <img src={Verifiedblue} alt="icon" style={{ width: '40px' }}></img>;
    obj.title = 'Metabilia Certification';
    obj.message =
      'This item is certified by Metabilia and/or the team, and includes matching tamper-evident stickers on the item and the certificate. Please enter the code visible on the sticker into the Metabilia database to verify authenticity.';

    obj.longmessage =
    'This item is certified by Metabilia and/or the team, and includes matching tamper-evident stickers on the item and the certificate. Please enter the code visible on the sticker into the Metabilia database to verify authenticity.';

    return obj;
  }

  if (value == 'Bid Only') {
    if (type == 'Normal') {
      if(max_bid){
        obj.icon = <img src={MaxBidIcon} alt="icon" style={{ width: '40px' }}></img>;
        obj.title = 'Max Bid';
        obj.message =
          'This is a traditional timed auction format with the highest bidder at the expiration of the auction being selected as winner. Users can enter a maximum bid, which is an irrevocable commitment to pay up to that level, if other users place competing bids that reach the max bid placed. If the bids entered exceed the max bid, the user will have an opportunity to increase their bid during the time remaining in the auction. There may or may not be a sniping window in place, whereby the time is extended in the auction if bids are placed within a short time prior to expiration.';
        obj.longmessage =
          'This is a traditional timed auction format with the highest bidder at the expiration of the auction being selected as winner. Users can enter a maximum bid, which is an irrevocable commitment to pay up to that level, if other users place competing bids that reach the max bid placed. If the bids entered exceed the max bid, the user will have an opportunity to increase their bid during the time remaining in the auction. There may or may not be a sniping window in place, whereby the time is extended in the auction if bids are placed within a short time prior to expiration.';  
      }
      else{
        obj.icon = <img src={Normal} alt="icon" style={{ width: '40px' }}></img>;
        obj.title = 'Traditional Auction';
        obj.message =
          'In this type of auction, items are offered at starting minimum price and every entry of a bid increases the next minimum bid. The user with the last bid is selected as winner at the predefined ending time, and results in a sale, assuming highest bid is above the reserve price (if any). If any bids are entered within the last minute, the auction time is extended, to permit additional users adequate time to enter another bid.';
        obj.longmessage =
          'In this type of auction, items are offered at starting minimum price and every entry of a bid increases the next minimum bid.  The user with the last bid is selected as winner at the predefined ending time, and results in a sale, assuming highest bid is above the reserve price (if any).  If any bids are entered within the last minute, the auction time is extended, to permit additional users adequate time to enter another bid.';
  
      }

      return obj;
    } else if (type == 'Sealed Bid Auction') {
      obj.icon = <img src={Sealedbid} alt="icon" style={{ width: '40px' }} />;
      obj.title = 'Sealed Bid Auction';
      obj.message =
        'In this type of auction, users submit one sealed bid to the auctioneer, who opens all bids at time of auction closing. The highest bid submitted during the auction window is determined as winner and the item is sold to that bidder for their bid price, assuming it is above the reserve price (if any).';
      obj.longmessage =
        'In this type of auction, users submit one sealed bid to the auctioneer, who opens all bids at time of auction closing. The highest bid submitted during the auction window is determined as winner and the item is sold to that bidder for their bid price, assuming it is above the reserve price (if any).';

      return obj;
    } else if (type == 'Volume Reserve Auction') {
      if(charge_highest_bid){
        obj.icon = <img src={Yankee} alt="icon" style={{ width: '40px' }} />;
        obj.title = 'Yankee Auction';
        obj.message =
          'Multiple items of the same SKU are offered, so that there may be more than one winning bidder. Each bidder can bid for one item and indicates the price that he/she is willing to pay for the item. Bids must be entered at higher level than either the starting bid or the highest previously entered bid. All bidders entering qualifying bids above the reserve will win the item at their highest bid price. If there are more qualifying bids than items available, priority goes to the bidders who submitted the higher bid.';
        obj.longmessage =
          'Multiple items of the same SKU are offered, so that there may be more than one winning bidder. Each bidder can bid for one item and indicates the price that he/she is willing to pay for the item. Bids must be entered at higher level than either the starting bid or the highest previously entered bid. All bidders entering qualifying bids above the reserve will win the item at their highest bid price. If there are more qualifying bids than items available, priority goes to the bidders who submitted the higher bid.';
        return obj;
      }
      else{
          obj.icon = <img src={Volumereserve} alt="icon" style={{ width: '40px' }} />;
          obj.title = 'Volume Reserve Auction';
          obj.message =
            'Type of auction, items are offered at starting minimum price and every entry of a bid increases the next minimum bid.  The user with the last bid is selected as winner at predefined ending time, unless bids are entered in the last minute, resulting in extended time.  Bids placed above the reserve price will be selected as winners up to the available quantity of the item offered.  All of the available items are sold to winning bidders at the reserve price.';
          obj.longmessage =
            'In this type of auction, items are offered at a starting minimum price and every entry of a bid increases the next minimum bid. At the end of the auction, bids placed above the reserve price will be selected as winners up to the available quantity of the item offered. All of the available items are sold to winning bidders at the reserve price.';
          return obj;
         }
    }
  }

  if (value == 'Auction') {
    if (type == 'Normal') {
      obj.icon = <img src={Flashauction} alt="icon" style={{ width: '40px' }}></img>;
      obj.title = 'Flash Auction';
      obj.message =
        'Enter rounds as price escalates. Bidder entering last round wins. Only the winner is charged for the item.';

      obj.longmessage =
        'Type of auction in which bidders enter into timed rounds at predefined escalating prices. If more than one bidder enters into the round during its open time, another timed round is started at a higher price. Additional rounds are added until only one bidder enters, who is selected as the winner. If there are two or more bidders in one round, but no bidders in the following round, the first bidder in the preceding round is selected as winner.';

      return obj;
    } else if (type == 'Dutch Auction') {
      obj.icon = <img src={Ducthauction} alt="icon" style={{ width: '40px' }} />;
      obj.title = 'Dutch Auction';
      obj.message = 'Offers start high and move down progressively, first bidder wins.';

      obj.longmessage =
        'Type of auction in which the price of an item is lowered until it gets a bid. The first bid made is the winning bid and results in a sale, assuming that the price is above the reserve price (if any).';
      return obj;
    }
  }

  if (value == 'Raffle') {
    obj.icon = <img src={Giveawey} alt="icon" style={{ width: '40px' }} />;
    obj.title = 'Giveaway';
    obj.message =
      'This item is offered as a Free Giveaway promotion.  One entry per eligible user.  Winner(s) will be selected at random from all eligible entries at the conclusion of the giveaway contest.  NO PURCHASE NECESSARY.';

    obj.longmessage =
      'This item is offered as a Free Giveaway promotion.  One entry per eligible user.  Winner(s) will be selected at random from all eligible entries at the conclusion of the giveaway contest.  NO PURCHASE NECESSARY.';
    return obj;
  }

  if (value == 'Discount' && type == 'Normal') {
    obj.icon = <img src={Discount} alt="icon" style={{ width: '40px' }}></img>;
    obj.title = 'Discount';
    obj.message = 'Avail a percentage or absolute amount discount on your next purchase.';
    obj.longmessage = 'Avail a percentage or absolute amount discount on your next purchase.';
    return obj;
  }
  // return obj
};

export { AuctionIcon };
